import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import styled from "styled-components"
import Markdown from "markdown-to-jsx"
import StandradFeatures from "../components/standard-features"
import RoomGallery from "../components/room-gallery"

import { LocaleContext } from "./layout"
import theme from "../components/theme"
import roomData from "../data/rooms.yaml"

const RoomHeadline = styled.h2`
  &:after {
    content: "";
    display: block;
    height: 5px;
    width: 20%;
    background: ${theme.colors.info};
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }
`

export default function RoomFeatures(props) {
  const {
    pageContext: { lang },
  } = React.useContext(LocaleContext)

  const data = roomData[props.name]

  return (
    <section className="py-5">
      <Container>
        <Row>
          <Col md="6" xl="4">
            <Markdown>{data.wording[lang]}</Markdown>
            <RoomHeadline className="h3 font-weight-normal">{roomData.roomAmenities[lang]}</RoomHeadline>
            <ul className="mt-3">
              <li>{`${data.size} ${data.sizeUnit[lang]} ${data.view[lang]}`}</li>
              <StandradFeatures />
            </ul>
          </Col>
          <Col md="6" xl="8">
            <RoomGallery room="deluxe" />
          </Col>
        </Row>
      </Container>
    </section>
  )
}
