import React from 'react'
import roomData from '../data/rooms.yaml'
import { LocaleContext } from "./layout"

export default function StandardFeatures() {
  const {
    pageContext: { lang },
  } = React.useContext(LocaleContext)

  const data = roomData.standardFeatures[lang];
  
  return (
    data.map(feature => <li key={feature}>{feature}</li>)
  )
}
