import React from "react"
import { Row, Col } from "react-bootstrap"
import styled from "styled-components"

import theme from "../components/theme"
import roomData from "../data/rooms.yaml"

const ImageMask = props => {
  return (
    <div className={props.className}>
      <img src={props.src} alt={props.alt} />
    </div>
  )
}

const Image = styled(ImageMask)`
  width: ${props => props.width || `100%`};
  height: ${props => props.height || `320px`};
  overflow: hidden;
  box-shadow: ${theme.shadow.main};

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
export default function RoomGallery(props) {
  const images = roomData[props.room].gallery
  return (
    <>
      <Row>
        <Col className="mt-lg-0">
          <Image src={`/images/${images[0]}`} alt="image222" />
        </Col>
      </Row>
      <Row>
        <Col lg="4">
          <Image
            src={`/images/${images[1]}`}
            alt="image222"
            height="200px"
            className="mt-4"
          />
          <Image
            src={`/images/${images[2]}`}
            alt="image222"
            height="200px"
            className="mt-4"
          />
        </Col>
        <Col lg="4">
          <Image
            src={`/images/${images[3]}`}
            alt="image222"
            height="424px"
            className="mt-4"
          />
        </Col>
        <Col lg="4">
          <Image
            src={`/images/${images[4]}`}
            alt="image222"
            height="200px"
            className="mt-4"
          />
          <Image
            src={`/images/${images[5]}`}
            alt="image222"
            height="200px"
            className="mt-4"
          />
        </Col>
      </Row>
      {/* <Row>
        <Col lg="6">
          <Image
            src={`/images/${images[6]}`}
            alt="image222"
            height="150px"
            className="mt-4"
          />
        </Col>
        <Col>
          <Image
            src={`/images/${images[7]}`}
            alt="image222"
            height="150px"
            className="mt-4"
          />
        </Col>
      </Row> */}
    </>
  )
}
