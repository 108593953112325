import React from "react"

import SEO from "../../components/seo"
import PageHeader from "../../components/page-header"
import RoomFeatures from "../../components/room-content"
// import RoomSimilar from "../../components/room-similar"
import roomData from "../../data/rooms.yaml"

const DeluxeRoom = (props) => {
  const {
    pageContext: { lang },
  } = props
  const { deluxe } = roomData
  return (
    <>
      <SEO title="Deluxe Room" />
      <PageHeader
        headerImage={deluxe.headerImage}
        headerAlt={deluxe.headerAlt}
        title={deluxe.title}
        info={`${deluxe.size} ${deluxe.sizeUnit[lang]} / ${deluxe.maxGuest} guests`}
      />
      <RoomFeatures name="deluxe" />
      {/* <RoomSimilar /> */}
    </>
  )
}

export default DeluxeRoom
